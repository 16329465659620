import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby"
import "typeface-lato"

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404 - nicht gefunden" />

      <Layout>
        <div className="not-found-wrapper">
          <h1>404 - nicht gefunden</h1>
          <p>Leider haben wir die von Ihnen gewünschte Seite nicht gefunden.</p>
          <p>
            <Link to="/">Zurück zur Startseite</Link>
          </p>
          <p>
            Gerne können Sie sich auch über unser{" "}
            <Link to="/#section_1">Kontaktformular</Link> melden oder schreiben
            Sie uns eine <a href="mailto:info@derfeineteig.de">E-Mail</a>. Wir
            freuen uns von Ihnen zu hören.
          </p>
        </div>
      </Layout>
    </>
  )
}

export default NotFoundPage
